<template>
  <div class="row">
    <b-alert show class="m-0 w-100 p-2 rounded-0" v-if="available<10" variant="warning">
      <small>{{$t('maxAvailablePersonCount',[available])}}</small>
    </b-alert>
    <div class="col-12">
      <div class="form-row py-2" v-if="ticket.adult.access===1">
        <div class="col py-2">
          <b v-if="ticket.children.access===0 && ticket.senior.access===0">Artikel</b>
          <b v-else>{{$t("adults")}}</b>
          <div class="w-100"></div>
          <small class="text-muted" v-if="ticket.children.access===0 && ticket.senior.access===0">
            {{ticket.title}}
          </small>
          <small v-else class="text-muted">
            {{$t("maxAdults")}} {{ticket.adult.max}}
          </small>
        </div>
        <div class="col-auto pr-3 py-2" v-if="ticket.adult.extraPrice>0">
          <b>{{$helper.convertToCurrency(ticket.adult.extraPrice)}}</b>
        </div>
        <div class="col-auto py-2">
          <div class="form-row">
            <div class="col">
              <a class="cursor" @click="decCount('adults')">
                <b-icon-dash-circle />
              </a>
            </div>
            <div class="col-auto text-center" style="width: 40px">
              {{form.adults.qty}}
            </div>
            <div class="col">
              <a class="cursor" @click="incCount('adults')">
                <b-icon-plus-circle />
              </a>
            </div>
          </div>

        </div>
      </div>
      <div class="form-row py-2" v-if="ticket.children.access===1">
        <div class="col py-2">
          <b>{{$t("children")}} (3-17)</b>
          <div class="w-100"></div>
          <small class="text-muted">
            {{$t("maxChildren")}} {{ticket.children.max}}
          </small>
        </div>
        <div class="col-auto pr-3 py-2" v-if="ticket.children.extraPrice>0">
          <b>{{$helper.convertToCurrency(ticket.children.extraPrice)}}</b>
        </div>
        <div class="col-auto py-2">
          <div class="form-row">
            <div class="col">
              <a class="cursor"  @click="decCount('children')">
                <b-icon-dash-circle />
              </a>
            </div>
            <div class="col-auto text-center" style="width: 40px">
              {{form.children.qty}}
            </div>
            <div class="col">
              <a class="cursor" @click="incCount('children')">
                <b-icon-plus-circle />
              </a>
            </div>
          </div>

        </div>
      </div>
      <div class="form-row py-2" v-if="ticket.senior.access===1">
        <div class="col py-2">
          <b>{{$t("senior")}} (60+)</b>
          <div class="w-100"></div>
          <small class="text-muted">
            {{$t("maxSeniors")}}  {{ticket.senior.max}}
          </small>
        </div>
        <div class="col-auto pr-3 py-2" v-if="ticket.senior.extraPrice>0">
          <b>{{$helper.convertToCurrency(ticket.senior.extraPrice)}}</b>
        </div>
        <div class="col-auto py-2">
          <div class="form-row">
            <div class="col">
              <a class="cursor"  @click="decCount('senior')">
                <b-icon-dash-circle />
              </a>
            </div>
            <div class="col-auto text-center" style="width: 40px">
              {{form.senior.qty}}
            </div>
            <div class="col">
              <a class="cursor"  @click="incCount('senior')">
                <b-icon-plus-circle />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <b-form-group class="col-12">
          <b-form-checkbox name="agreeAgb" v-model="form.agree.agb" value="1" unchecked-value="0">
            <span v-html="$t('agreeOurAgb')"></span>
          </b-form-checkbox>
        </b-form-group>
        <b-form-group class="col-12" v-if="checkCovid">
          <b-form-checkbox name="agreeCorona" value="1" unchecked-value="0"
                           v-model="form.agree.covid">
            <span v-html="$t('agreeOurCovidRegulation')"></span>
          </b-form-checkbox>
        </b-form-group>
      </div>
      <div class="form-row">
        <div class="col text-left py-2">
          <b-button variant="grey-light" @click="goBack">
            <b-icon-arrow-left-short /> {{$t("back")}}
          </b-button>
        </div>
        <div class="col-auto text-right py-2">
          <b-button variant="yellow" @click="goNext">
            <span v-if="ticket.bookBy==='2'">{{$t('continue')}}</span>
            <span>{{$t('buyNow')}}</span>
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BuyTicketCounts",
  props : {
    offer : Object,
    action : Object,
    form : Object,
    ticket : Object,
    available: Number,
    checkCovid: Boolean
  },
  methods : {
    getTotalPersonExceed () {
      const total = this.form.adults.qty+this.form.children.qty+this.form.senior.qty;
      if (total > this.available) {
        this.action.haveError = true;
        this.action.errorMessage = this.$t("errorMaximumAvailableSlot",[this.available]);
      } else {
        this.action.haveError = false;
      }
    },
    goBack() {
      this.action.step = 1;
    },
    goNext() {
      const totalPerson = this.form.adults.qty+this.form.children.qty+this.form.senior.qty;
      if(totalPerson > this.available) {
        this.action.haveError = true;
        this.action.errorMessage = this.$t("errorMaximumAvailableSlot",[this.available]);
      } else if(totalPerson<1) {
        this.action.haveError = true;
        this.action.errorMessage = this.$t("selectMinOneGuest");
      } else if(this.form.totalPrice<=0) {
        this.action.haveError = true;
        this.action.errorMessage = this.$t("priceShouldAboveZero");
      } else if(this.form.agree.agb==='0') {
        this.action.haveError = true;
        this.action.errorMessage = this.$t("pleaseAgreeAgb");
      } else if(this.form.agree.covid==='0' && this.checkCovid) {
        this.action.haveError = true;
        this.action.errorMessage = this.$t("pleaseAgreeCovidRegulation");
      } else {
        this.action.haveError = false;
        this.$emit("gonextaftercount");
      }
    },
    incCount(personType) {
      this.action.haveError = false;
      if(personType==="adults") {
        if(this.form.adults.qty<this.ticket.adult.max) {
          this.form.adults.qty++;
        }
      } else if(personType==="children") {
        if(this.form.children.qty<this.ticket.children.max) {
          this.form.children.qty++;
        }
      } else if(personType==="senior") {
        if(this.form.senior.qty<this.ticket.senior.max) {
          this.form.senior.qty++;
        }
      }
      this.getTotalPersonExceed();
      this.setTotal();
    },
    decCount(personType) {
      this.action.haveError = false;
      if(personType==="adults") {
        this.form.adults.qty = (this.form.adults.qty>this.ticket.adult.min) ? this.form.adults.qty-1 : this.form.adults.qty;
      } else if(personType==="children") {
        this.form.children.qty = (this.form.children.qty>this.ticket.children.min) ? this.form.children.qty-1 : this.form.children.qty;
      } else if(personType==="senior") {
        this.form.senior.qty = (this.form.senior.qty>this.ticket.senior.min) ? this.form.senior.qty-1 : this.form.senior.qty;
      }
      this.getTotalPersonExceed();
      this.setTotal();
    },
    setTotal() {
      let ticketPrice = this.ticket.price;
      let adultPrice = 0;
      let childrenPrice = 0;
      let seniorPrice = 0;

      if(this.form.adults.qty>this.ticket.adult.min) {
            const adultExceed = this.form.adults.qty-this.ticket.adult.min;
            adultPrice = adultExceed*this.ticket.adult.extraPrice;
            this.form.adults.totalPrice = adultPrice;
      } else {
        this.form.adults.totalPrice = 0;
      }
      if(this.form.children.qty>this.ticket.children.min) {
        const adultChildren = this.form.children.qty-this.ticket.children.min;
        childrenPrice = adultChildren*this.ticket.children.extraPrice;
        this.form.children.totalPrice = childrenPrice;
      } else {
        this.form.children.totalPrice = 0;
      }
      if(this.form.senior.qty>this.ticket.senior.min) {
        const adultSenior = this.form.senior.qty-this.ticket.senior.min;
        seniorPrice = adultSenior*this.ticket.senior.extraPrice;
        this.form.senior.totalPrice = seniorPrice;
      } else {
        this.form.senior.totalPrice = 0;
      }
      this.form.totalPrice = ticketPrice+adultPrice+childrenPrice+seniorPrice;

    }
  }
}
</script>

<style scoped>

</style>
