<template>
  <div class="py-2">
    <div class="w-100" v-for="(guest,index) in guests" :key="'normalGuests'+index">
      <div class="form-row border rounded-lg pb-2 mb-2">
        <div class="col-12 py-2">
          <b class="text-yellow">
            <span class="text-secondary">{{index+1}}. </span>
            <span v-if="guest.type===1">Erwachsene (12+)</span>
            <span v-else-if="guest.type===2">Kinder (4-11)</span>
            <span v-else>Senioren (60+)</span>
          </b>
        </div>
        <b-form-group class="col-12 col-sm" label="Vorname *">
          <b-form-input autocomplete="off" v-model="guest.firstName" />
        </b-form-group>
        <b-form-group class="col-12 col-sm" label="Nachname *">
          <b-form-input autocomplete="off" v-model="guest.lastName" />
        </b-form-group>
        <b-form-group class="col-12 col-sm" label="Geburtsdatum *">
          <b-form-input autocomplete="off" placeholder="tt.mm.jjjj"
                        v-model="guest.dob"/>
        </b-form-group>
      </div>
    </div>

    <div class="form-row">
      <div class="col text-left py-2" @click="goBack">
        <b-button variant="grey-light">
          <b-icon-arrow-left-short /> {{$t("back")}}
        </b-button>
      </div>
      <div class="col-auto text-right py-2" @click="goNext">
        <b-button variant="yellow">
          {{$t("buyNow")}}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BuyTicketGuest",
  props : {
    guests : Array,
    action : Object
  },
  methods : {
    goBack() {
      this.action.step--;
    },
    goNext() {
      this.$emit("gonext");
    }
  }
}
</script>

<style scoped>

</style>
