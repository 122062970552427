<template>
  <div class="container-fluid py-5">
    <div class="row">
      <div class="container py-5 text-center text-white" v-if="action.state==='loading'">
        <div-loader />
      </div>
      <div class="container text-center" v-else-if="action.state==='error'">
        <error-div :message="action.stateMessage" />
      </div>
      <div class="container" v-else>
        <div class="form-row">
          <div class="col-12 mb-2 col-md-7">
            <div class="w-100 p-2 rounded-lg bg-white">
              <b-img-lazy :src="ticket.image" fluid class="w-100 rounded-lg" />
              <div class="w-100 py-2">
                <b class="textColor">{{ticket.title}}</b>
                <div class="w-100"></div>
                <p class="m-0 text-muted">
                  {{ticket.info}}
                </p>
                <b-alert :show="ticket.customDate.enable==='1' && ticket.customDate.info!==''" variant="warning"
                         class="mt-2 px-2 py-1">
                  <small>{{ticket.customDate.info}}</small>
                </b-alert>
              </div>
            </div>
          </div>
          <div class="col-12 mb-2 col-md-5">
            <b-overlay :show="action.loading || voucher.state==='loading'">
              <div class="w-100 rounded-lg bg-white">
                <div class="w-100 p-2 border-bottom">
                  <b class="textColor">{{$t("bookTicket")}}
                    <span class="textColor" v-if="form.date !== null">
                      ({{$helper.convertToCurrency($helper.afterOfferDiscount(form.totalPrice,offer))}})
                    </span>
                    <del class="text-danger" v-if="form.date !== null && offer.haveOffer">
                      <small>({{$helper.convertToCurrency(form.totalPrice)}})</small>
                    </del>
                  </b>
                </div>
                <div class="col-12 border-bottom d-none">
                  <div class="form-row">
                    <div class="col-12 pt-2">
                      {{$t('applyVoucher')}}
                    </div>
                    <div class="col py-2">
                      <b-input-group>
                        <b-form-input
                            style="text-transform: uppercase"
                            :disabled="voucher.applied || voucher.state==='loading'"
                            v-model="voucher.code" autocomplete="off" />
                        <template #append v-if="voucher.applied">
                          <b-input-group-text >{{$helper.convertToCurrency(realVoucherAmount)}}</b-input-group-text>
                        </template>

                      </b-input-group>

                      <span class="text-danger">{{voucher.message}}</span>
                    </div>
                    <div class="col-auto py-2">
                      <b-button variant="danger" @click="removeVoucher" v-if="voucher.applied">
                        <b-icon-trash />
                      </b-button>
                      <b-button v-else @click="applyVoucher" :disabled="voucher.state==='loading'">
                        <b-spinner v-if="voucher.state==='loading'" small class="float-left mt-1 mr-2" />
                        {{$t('apply')}}
                      </b-button>
                    </div>
                  </div>
                </div>
                <div class="w-100" v-if="action.step===1">
                  <date-picker v-model="form.date"
                               :is-expanded="true"
                               :locale="$i18n.locale"
                               :first-day-of-week="2"
                               :disabled-dates='{ days: blockDays }'
                               @dayclick="onDateChoosed"
                               @update:to-page="checkAvailability"
                               mode="date"
                               trim-weeks
                               :min-date="setMinDate"
                               :max-date="setMaxDate"
                               color="green"
                               :model-config="modelConfig"
                               is-required/>
                  <div class="col-12 border-top py-2 text-right">
                    <b-button @click="goNext" variant="yellow">
                      {{$t('continue')}}
                    </b-button>
                  </div>
                </div>
                <div class="col-12 px-3" v-if="action.step===2">
                  <buy-ticket-counts ref="ticketCount"
                      :available="available" :check-covid="checkCovid"
                      :offer="offer"
                      @gonextaftercount="goNextAfterCount()"
                      :action="action"
                      :form="form"
                      :ticket="ticket"/>
                </div>
                <div class="col-12 px-3" v-if="action.step===3">
                  <buy-ticket-guest
                      @gonext="checkCustomer()"
                      :action="action"
                      :guests="form.guests"
                  />
                </div>
                <div class="w-100 p-2" v-if="action.haveError">
                  <b-alert variant="danger" :show="action.haveError" class="m-0">
                    <b>{{action.errorMessage}}</b>
                  </b-alert>
                </div>
              </div>
            </b-overlay>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BuyTicketCounts from "@/components/Home/BuyTicket/BuyTicketCounts";
import DivLoader from "@/components/Template/DivLoader";
import ErrorDiv from "@/components/Template/ErrorDiv";
import BuyTicketGuest from "@/components/Home/BuyTicket/BuyTicketGuest";
export default {
  name: "BuyTicket",
  components: {BuyTicketGuest, ErrorDiv, DivLoader, BuyTicketCounts},
  mounted() {
    this.loadTicket();
  },
  computed : {
    setMinDate () {
      const today = this.$moment().format('YYYY-MM-DD')
      if (this.ticket.customDate.enable==='1') {
        if (this.$moment(this.ticket.customDate.start).isBefore(today)) {
          return this.$moment().toDate()
          // return this.$moment(this.ticket.customDate.start,"YYYY-MM-DD").toDate()
        } else {
          return this.$moment(this.ticket.customDate.start,"YYYY-MM-DD").toDate()
        }
      } else {
        return this.$moment().toDate()
      }
    },
    setMaxDate () {
      if (this.ticket.customDate.enable==='1') {
        return this.$moment(this.ticket.customDate.end,"YYYY-MM-DD").toDate()
      } else {
        return this.$moment().add(1,'year').toDate()
      }
    },
    realVoucherAmount() {
      const totalAmount = this.$helper.afterOfferDiscount(this.form.totalPrice,this.offer);
      return (this.form.voucherAmount>totalAmount) ? totalAmount : this.form.voucherAmount;
    }
  },
  methods : {
    removeVoucher() {
      this.form.voucherCode = "";
      this.form.voucherAmount = 0;
      this.voucher.applied = false;
      this.voucher.code = "";
    },
    async applyVoucher() {
      this.form.voucherCode = "";
      this.form.voucherAmount = 0;
      this.voucher.state = 'loading';
      this.voucher.message = "";
      this.voucher.applied = false;
      const validate = await this.$parent.checkVoucher(this.voucher.code);
      if(validate.status) {
        this.voucher.state = 'success';
        this.form.voucherAmount = validate.amount;
        this.form.voucherCode = this.voucher.code;
        this.voucher.applied = true;
      } else {
        this.voucher.state = 'error';
        this.voucher.message = validate.message;
      }
    },
    onDateChoosed (elm) {
      this.haveError=false
      const thisDate =  this.$moment(elm.date).format('YYYY-MM-DD')
      const calData = this.calendarDays[thisDate];
      this.form.totalPrice = parseFloat(calData.price)
      this.available = parseInt(calData.allowedPerson);
      this.ticket.price = parseFloat(calData.price)
    },
    checkAvailability(param) {
      this.action.loading = true;
      this.action.haveError = false;
      this.$http.get("front/bookableTicketDays",{
        params : {
          month : param.month,
          year : param.year,
          ticket : this.ticket.id
        }
      }).then((response)=>{
        if(response.data.error===undefined) {
          this.action.haveError = true;
          this.action.errorMessage = this.$t("errors.unExpectedError");
        } else if(response.data.error) {
          this.action.haveError = true;
          this.action.errorMessage = response.data.message;
        } else {
          const lists = response.data.list;
          const newBlockOutDays = [];
          this.calendarDays = {};
          lists.forEach((list) => {
            if (list.status === 'close') {
              newBlockOutDays.push(list.monthDay);
            }
            this.calendarDays[list.date] = list;
          })
          this.blockDays = newBlockOutDays
        }
      }).catch(() => {
        this.action.haveError = true;
        this.action.errorMessage = this.$t("errors.unExpectedError");
      }).finally(()=>{
        this.action.loading = false;
      });
    },
    loadTicket() {
      const code = this.$route.params.code;
      this.action.state = "loading";
      this.$http.get("front/ticketDetail/"+code)
        .then(response=>{
          if(response.data.error===undefined) {
            this.action.state = "error";
            this.action.stateMessage = this.$t("errors.unExpectedError");
          } else if(response.data.error) {
            this.action.state = "error";
            this.action.stateMessage = response.data.message;
          } else {
            this.action.state = "success";
            this.ticket = response.data.detail;
            this.offer = response.data.offer;
            this.checkCovid = response.data.checkCovid;
            this.resetBuy();
          }
        }).catch(error=>{
          this.action.state = "error";
          this.action.stateMessage = error.toString();
      });
    },
    resetBuy() {
      this.action.saving = false;
      this.action.haveError = false;
      this.action.errorMessage = "";
      this.action.step = 1;
      this.totalPersons = 0;
      this.form = {
        voucherCode : "",
        voucherAmount : 0,
        id : this.ticket.id,
        bookBy : this.ticket.bookBy,
        date : null,
        totalPrice : this.ticket.price,
        agree : {
          covid: "0",
          agb: "0"
        },
        adults : { // jan 16 changed into zero
          qty : this.ticket.adult.min,
          totalPrice : 0
        },
        children : {
          qty : this.ticket.children.min,
          totalPrice : 0
        },
        senior : {
          qty : this.ticket.senior.min,
          totalPrice : 0
        },
        guests : []
      };
      this.$bvModal.show("buyTicketModal");
    },
    goNext() {
      this.action.haveError = false;
      if(this.form.date===null) {
        this.action.haveError = true;
        this.action.errorMessage = this.$t("pleaseSelectDate");
      } else {
        this.action.haveError = false;
        this.action.step = 2;
        setTimeout(() => {
          this.$refs.ticketCount.getTotalPersonExceed();
          this.$refs.ticketCount.setTotal();
        }, 100)
      }
    },
    goNextAfterCount() {
      if(this.ticket.bookBy==="2") {
        this.form.guests = [];
        let newGuests = [];
        for (let i = 0; i<this.form.adults.qty;i++) {
          newGuests.push({
            type : 1,
            firstName : "",
            lastName : "",
            dob : "",
          });
        }
        for (let i = 0; i<this.form.children.qty;i++) {
          newGuests.push({
            type : 2,
            firstName : "",
            lastName : "",
            dob : "",
          });
        }
        for (let i = 0; i<this.form.senior.qty;i++) {
          newGuests.push({
            type : 3,
            firstName : "",
            lastName : "",
            dob : "",
          });
        }
        this.form.guests = newGuests;
        this.action.step = 3;
      } else if(this.$store.state.loggedIn) {
        this.checkCustomer();
      } else {
        this.$bvModal.show("loginModal");
      }
    },
    checkCustomer() {
      const token = localStorage.getItem("FpCusAuthToken");
      if(token!==null && token!=='') {
        this.action.loading = true;
        this.$http.post("front/validateToken").then(response=>{
          if(response.data.error || response.data.error===undefined) {
            this.openNextStepAfterCustomerCheckFail();
          } else {
            this.createBooking();
          }
        }).catch(()=>{
          this.action.haveError = true;
          this.action.errorMessage = this.$t("errors.unExpectedError");
        }).finally(()=>{
          this.action.loading = false;
        });
      } else {
        this.openNextStepAfterCustomerCheckFail();
      }
    },
    openNextStepAfterCustomerCheckFail() {
      this.action.loading = false;
      this.action.haveError = false;
      this.$store.commit("setLogout");
      this.$bvModal.show("loginModal");
    },
    createBooking() {
      this.action.loading = true;
      this.form.offer = this.offer;
      this.$http.post("front/newBooking",this.form)
          .then(response=>{
            if(response.data.error===undefined) {
              this.action.loading = false;
              this.action.haveError = true;
              this.action.errorMessage = this.$t("errors.unableToBook");
            } else if(response.data.error) {
              this.action.loading = false;
              this.action.haveError = true;
              this.action.errorMessage = response.data.message;
            } else {
              this.action.haveError = false;
              window.location = response.data.url;
            }
          }).catch(error=>{
            this.action.loading = false;
            this.action.haveError = true;
            this.action.errorMessage = error.toString();
      });
    }
  },
  data() {
    return {
      checkCovid: true,
      available: -1,
      offer : {},
      afterDiscount : 0,
      voucher : {
        state : "",
        message : "",
        code : "",
        applied : false
      },
      action : {
        loading : false,
        saving : false,
        state : 'loading',
        stateMessage : '',
        haveError : false,
        errorMessage : "",
        step : 1,
      },
      ticket : {},
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      blockDays : [],
      calendarDays : {},
      minDate : new Date(),
      maxDate : this.$moment().add(1,'year').toDate(),
      form : {},
      totalPersons : 0,
      toDo: [
        {
          description: 'Take Noah to basketball practice.',
          isComplete: false,
          dates: { weekdays: 6 }, // Every Friday
          color: 'red',
        },
      ]
    }
  }
}
</script>

<style>
.vc-container  {
  border:  none !important;
  border-radius: 0 !important;
}
.cursor {
  cursor: pointer;
}
</style>
